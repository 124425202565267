@import '../../../../utilities/main-styles.scss';
@import '../../../../utilities/variables.scss';
@import '../common_blocks/FrontPage/FrontPage.scss';

.sestrica-main{
    @include prime-body();
    
    background: linear-gradient(180deg, #5BC815 17.49%, #4EB00F 100%), #5BC815;


    &__info{
       @include prime-info();
    }

    &__text{
        @include prime-text(); 
    }

    &__link{
        @include prime-link();
    }

    &__tablet{
        z-index: 2;
        text-align: center;
        @media screen and (min-width: 1280px) {
            margin-top: -20px;
        }
    }

    &__image{
        @media screen and (max-width: 1279px) {
            width: 100%;
            height: auto;
            
        }
    }
}

.sestrica-goal{
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 70px;


    &__text-block{
        padding: 0px 7% 0px;
    }

    &__title{
        @include main-text(500, 32px, 119%);

        position: relative;
        float: left;
        padding-bottom: 3px;
    }
    
    &__title::after{
        content: "";
        position: absolute;
        border-bottom: 2px solid $secondary-color;
        width: 28px;
        left: 0;
        bottom: 0;
    }


    &__text{
        @include main-text(normal, 18px, 140%);
        
        @media screen and (min-width: 1280px){
            width: 752px;
            padding-left: 64px;
        }

        float: left;
        text-align: justify;
        padding-top: 10px;
    }

    &__image-block{
        @media screen and(min-width: 1280px) {
            text-align: center;
            padding-left: 120px;
        }
        justify-content: center;   
    }

    &__image{
        @media screen and(max-width: 1279px) {
            width: 70%;
            height: auto;
            position: relative;
            transform: translateX(20%);
        }
    }
}

.sestrica-past{
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 55px;
    display: flex;
    flex-direction: column;

    &__text-block{
        padding: 0px 7% 0px;
    }

    &__title{
        @include common-title();
        float: left;
    }

    &__title::after{
        @include common-title_after();
    }

    &__text{
        @include main-text(normal, 18px, 140%);
        
        @media screen and (min-width: 1280px){
            width: 808px;
            padding-left: 64px;
        }

        float: left;
        text-align: justify;
        padding-top: 10px;
    }

    &__image-block{
        padding: 1% 0 5% 0;
    }

    &__img{
        width: 100%;
    }
}

.sestrica-design{
    @media screen and (min-width: 1280px) {
        height: 1717px;
    }
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 60px;
    display: flex;
    flex-direction: column;

    &__text-block{
        padding: 0px 7% 0px;
    }

    &__title{
        @include common-title();
        padding-bottom: 5px;
    }

    &__title::after{
        @include common-title_after();
    }

    &__text{
        @include main-text(normal, 18px, 140%);
        @media screen and (min-width: 1280px){
            width: 360px;
        }
        padding-top: 20px;
    }

    &__image-block{
        position: relative;
        @media screen and (max-width: 1280px) {
            padding: 0px 7% 0px;
            display: flex;
            flex-wrap: wrap;

        }
        
    }

    &__image1{
        @media screen and (min-width: 1280px) {
            @include image-position(0px, 8px, 1);
        }
        @media screen and (max-width: 500px){
            width: 100%;
            height: auto;
        }
        border: 1px solid #C4C4C4;
    }

    &__image2{
        @media screen and (min-width: 1280px) {
            @include image-position(-136px, 488px, 2);
        }
        @media screen and (max-width: 850px) {
            width: 100%;
            height: auto;
        }
        border: 1px solid #C4C4C4;
        border-radius: 6px;
    }

    &__image3{
        @media screen and (min-width: 1280px) {
            @include image-position(-151px, 475px, 1);
        }
        @media screen and (max-width: 1279px) {
            display: none;
        }
    }

    &__image4{
        @media screen and (min-width: 1280px) {
            @include image-position(520px, 255px, 3);
        }
        @media screen and (max-width: 350px){
            width: 100%;
            height: auto;
        }
    }

    &__image5{
        @media screen and (min-width: 1280px) {
            @include image-position(667px, 0px, 1);
        }
        @media screen and (max-width: 465px){
            width: 100%;
            height: auto;
        }
        border: 1px solid #C4C4C4;
    }
}

.sestrica-adaptive{
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;

    padding-top: 40px;
    display: flex;
    flex-direction: column;

    &__text-block{
        padding: 0px 7% 0px;
    }

    &__title{
        @include common-title();
    }

    &__title::after{
        @include common-title_after();
    }

    &__text{
        @include main-text(normal, 18px, 140%);
        
        @media screen and (min-width: 1280px){
            width: 512px;
        }

        padding-top: 20px;
    }

    &__image-block{
        position: relative;
        @media screen and (max-width: 1280px) {
             padding: 0px 7% 0px;
        }
        
    }

    &__image{
        width: 100%;
        height: auto;
    }
}

.sestrica-mobile{
    @media screen and (min-width: 1280px) {
        height: 675px;
    }
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 60px;
    display: flex;
    flex-direction: column;

    &__text-block{
        padding: 0 7% 0;
    }
    
    &__title{
        @include common-title();
        @media screen and (max-width: 1279px){
            margin-left: 0;
        }
    }

    &__title::after{
        @include common-title_after();
    }

    &__text-info{
        @include main-text(normal, 18px, 140%);
        
        @media screen and (min-width: 1280px){
            width: 512px;
        }
        padding-top: 20px;
    }

    &__media-block{
        padding: 0 7% 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        
    }

    &__text-download{
        @include main-text(500, 22px, 26px);
        @media screen and (min-width: 1280px) {
            padding-top: 100px;            
        }
        padding-top: 30px;
    
        letter-spacing: 0.05em;   
    }

    &__button-block{
        padding: 20px 0;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        @media screen and (min-width: 460px){
            width: 420px;
        }
    }

    &__appstore{
        @media screen and (max-width: 460px){
            padding-left: 5px;
            padding-bottom: 20px;
        }
    }

    &__image{
        margin-top: -175px;
        margin-right: 110px;
        @media screen and (max-width: 1280px) {
            display: none;
        }
    }
}