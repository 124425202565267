@import '../../utilities/variables.scss';
@import '../../utilities/main-styles.scss';

.nav-panel{

    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 15px 0;
    justify-content: end;
    // border: 1px solid;
    &__logo{
        align-self: flex-end;
        padding-left: 5.5%;
    }

    &__blue-logo{
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
    
    &__white-logo{
        @media screen and (min-width: 769px) {
            display: none;
        }
    }
}

.nav-collapse{
    flex-grow: 0;
    padding-right: 4.5%;
    text-align: right;
}

.collapsing{
    height: 0px !important;
    transition: max-height .1s ease !important;
}

.nav-menu{
    @media screen and (max-width: 767px) {
        position: absolute;
        right: 1%;
    }
    
    @media screen and (max-width: 550px) {
        position: absolute;
        right: 0;
    }
    

    &__item{
        @include main-text(300, 18px, 119%);
        padding: 8px 16px;
        @media screen and (max-width: 767px) {
            font-size: calc(12px + 0.7vw);
        }
        &_active{
            color: #3E7EE3;
            text-decoration: underline;
        }
    }
    
}

.sestrica_navbar-item{
    @media screen and (max-width: 768px){
        color: #FFFFFF;
    }
}

.college_navbar-item{
    @media screen and (max-width: 768px){
        color: #FFFFFF;
    }
}

.h2u_navbar-item{
    @media screen and (max-width: 768px){
            color: #FFFFFF;
    }
}

.redkey_navbar-item{
    @media screen and (max-width: 768px){
            color: #FFFFFF;
    }
}

.common-nav{
    @media screen and (max-width: 767px){
        background-color: rgba(255, 255, 255, 0.8);
    }
}

.nav-toggler{
    // margin-right: 7%;
    margin-right: 3%;
    padding: 2px 6px;
    width: 62px;
    height: 54px;
}

.custom-border{
    border-color: #FFFFFF !important;
}

.common-border{
    border-color: $secondary-color !important;
}

.custom-toggler{
    color: #FFFFFF;
}

.common-toggler{
    color: $secondary-color;
}

button:focus{
    outline: none;
}