@import '../../utilities/variables.scss';
@import '../../utilities/main-styles.scss';
@import '../../blocks/button/bem-button.scss';

.contacts{
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 50px;

    &__alert{
        display: none;
    }

    &__text-block{
        padding: 0 5.5% 0;
        display: flex;
        flex-direction: column;
    }

    &__title{
        @include main-text(500, 32px, 38px);
    }

    &__text{
        @include main-text(normal, 24px, 28px);
    }

    &__form{
        &-block{
            @media screen and (min-width: 768px){
                padding: 20px 9% 100px;
            }
            @media screen and (max-width: 768px){
                padding: 4% 5.5%;
            }
        }

        &-body{
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &-item{
            padding-bottom: 10px;
            @media screen and (min-width: 768px) {
                flex-basis: 45%;  
            }
            @media screen and (max-width: 768px) {
                flex-basis: 100%;
            }            
        }

        &-modal-item{
            padding-bottom: 10px;
            @media screen and (min-width: 768px) {
                flex-basis: 48%;  
            }
            @media screen and (max-width: 768px) {
                flex-basis: 100%;
            }            
        }
        &-label{
            @include main-text(normal, 14px, 17px);
        }

        &-control{
            height: 48px;
            background: #FFFFFF;
            border: 1px solid rgba(30, 50, 65, 0.3);
            box-sizing: border-box;
            border-radius: 4px;
        }

        &-control:focus{
            background: #FFFFFF;
            border: 1px solid $secondary-color;
            box-sizing: border-box;
            border-radius: 4px;
            box-shadow: none;
            caret-color: $secondary-color;
        }

        &-control:valid:not(:placeholder-shown){
            background: #FFFFFF;
            border: 1px solid #1E3241;
            box-sizing: border-box;
            border-radius: 4px;
        }

        &-button-block{
            // text-align: right;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-end;
            padding: .75rem;
        }

        &-button{
            width: 208px !important;
        }
    }

    &__label-error{
        @include main-text(normal, 14px, 17px);
        color: #F22929;
    }

    &__control-error{
        height: 48px;
        background: #FFFFFF;
        border: 1px solid #F22929;
        box-sizing: border-box;
        border-radius: 4px;
    }

    &__control-error:focus{
        background: #FFFFFF;
        border: 1px solid  #F22929;
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: none;
        caret-color: $secondary-color;
    }

    &__error{
        @include main-text(normal, 12px, 14px);
        position: absolute;
        color: #F22929;
        padding-top: 7px;
    }

    &__spinner {
        margin: 0px 0px 2px 10px;
    }
}

.close-button{
    width: 208px !important;
    background: #fff !important;
    border: none !important;
    box-shadow: none !important;
    &__text{
        color: #9F9F9F !important;
    }
    margin-right: 1.8%;
    @media screen and (max-width: 516px) {
        margin-left: 10px;
        margin-right: 0px;
        margin-bottom: 10px;
    }
}

.close-button:hover{
    border-radius: 8px !important;
    background: #F2F2F2 !important;
}

.close-button:active{
    background: #DDDDDD !important;
    border-radius: 8px !important;
}