@import '../../../utilities/variables.scss';
@import '../../../utilities/main-styles.scss';

.advantages-block{
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 40px 0 20px;

    &__title{
        @include main-text(500, 48px, 119%);
        @media screen and (max-width: 1279px){
            font-size: calc(30px + 1.4vw);
        }
        padding-left: 5.5%;
    }
}

.media-container{
    padding: 25px 5.5% 30px;
}

.media-block{
    &-left{
        @media screen and (max-width: 980px) {
            flex-direction: column;
        }   
        margin: 30px 0px; 
    }

    &-right{
        @media screen and (max-width: 980px) {
            flex-direction: column;
        }
        margin: 30px 0px;
        padding-left: calc(50% - 304px/2 - 320px);
        flex-direction: row-reverse;
    }

    &-custom{
        @media screen and (max-width: 980px) {
            flex-direction: column;
        }
        margin: 30px 0px;
        padding-left: calc(50% - 304px/2 - 320px);
    }

    &__image-left{
        @media screen and (min-width: 1280px){
            padding-right: 88px;
        }
        width: 100%;
        padding-right: 7%;
    }

    &__custom-image{
        @media screen and (min-width: 1280px){
            padding-right: 160px;
        }
        width: 100%;
        padding-right: 15%;
    }

    &__image-right{
        width: 100%;
        @media screen and (min-width: 980px) {
            padding-left: 7%;
        }
    }

    &__body{
        @media screen and (min-width: 920px) {
            display: table;
            height: 280px;   
        }
    }

    &__body-content{
        display: table-cell;
        vertical-align: middle;
        @media screen and (max-width: 980px){
            padding-top: 20px;
        }
    }

    &__body-header{
        @include main-text(500, 32px, 119%);
        @media screen and (min-width: 1280px){
            width: 504px;
        }
        @media screen and (max-width: 1279px){
            font-size: calc(24px + 0.6vw);
        }
        margin-bottom: 24px;
    }

    &__body-text{
        @include main-text(300, 16px, 130%);
        @media screen and (min-width: 1280px) {
            width: 488px;
        }   
    }
}

.line-break{
    @media screen and (min-width: 980px) {
        visibility: hidden;
    }
}

.custom-header{
    width: 300px;
}