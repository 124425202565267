@keyframes animation {
    0% {
        background-color: #fff;
    }
    50% {
        background-color: #ccc;
    }
    100% {
        background-color: #ccc;
    }
}

.placeholder {
    animation-name: animation !important;
    animation-duration: 1s !important;
}