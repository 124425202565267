@import '../../utilities/variables.scss';

.bem-button{
    
    background: $secondary-color;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    width: 240px;
    height: 56px;

    &__text{
        font-family: $primary-family;
        font-style: $primary-style;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;


        text-align: center;
        text-transform: uppercase;

        color: #FFFFFF;

        border-radius: 28px;
    }
}

.bem-button:hover{
    box-shadow: 0px 8px 16px rgba(39, 98, 191, 0.32);
}

.bem-button:focus{
    box-shadow: none !important;
    background: linear-gradient(180deg, #2162C9 -14.29%, #3E7EE3 100%);
}