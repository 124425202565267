@import '../../../utilities/variables.scss';
@import '../../../blocks/button/bem-button.scss';
@import '../../../utilities/main-styles.scss';

.main{
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 110px 0 60px;

    display: flex;
    flex-direction: row;
    @media screen and (max-width: 1200px) {
        flex-direction: column;
        padding-top: 50px 0px 0px;
    }
    @media screen and (max-width: 1355px) {
        overflow: hidden;
    }
}

.main-info {

    &__text-block{
        @media screen and (max-width: 1200px) {
        align-self: center;
        align-items: center;
        height: 350px;
        padding-right: 5.5%;
    }
    padding-left: 5.5%;
    display: flex;
    flex-direction: column;
    height: 456px;

    }
    
    &__title{
        flex-grow: 1;
        @include main-text(300, 52px, 119%);
        width: 562px;

        @media screen and (max-width: 1200px) {
            text-align: center;
            width: auto;
            padding-bottom: 40px;
            font-size: calc(30px + 2.2vw);
        }
        
        &-bold{
            font-weight: 500;
        }

    }

    &__subtitle{
        flex-grow: 1; 
        margin-top: -30px;
        @media screen and (max-width: 1200px) {
            text-align: center;
        }

        &-text{
            @include main-text(300, 32px, 119%)
        }
    }

    &__button-block{
        flex-grow: 3;
    }

    &__media{
        padding-right: 5.5%;
        @media screen and (max-width: 1200px) {
            display: none;
        }
        @media screen and (min-width: 1200px){ 
            margin-top: -50px;
            margin-left: 45px;
        }  
        width: 47%;
    }
    
    &__slides-block{
        height: 456px;
        width: 570px;
        margin-left: 10px;
    }

    &__slides-img{
        @include image-position(-70px, -160px, 2);
        width: 800px;
        // width: 140%;
    }

    &__slides3-img{
        @include image-position(0px, -40px, 1);
    }

    &__slides4-img{
        @include image-position(0px, 45px, 1);
    }

    &__carousel-content{
        position: relative;
        margin: 20px;
        background: #FFFFFF;
        border: 16px solid #FFFFFF;
        box-shadow: 0px 4px 24px rgba(34, 65, 114, 0.16);
        border-radius: 16px;
        z-index: 3;
    }
}

.carousel-inner{
    overflow: visible;
    display: block;
}

.carousel-indicators{
    bottom:-50px;
}

.carousel-indicators li{
    width: 16px;
    height: 16px;
    margin-left: 12px;
    margin-right: 12px;
    border-radius: 100%;
    background: #FFFFFF;
    border: 1px solid $secondary-color;
    box-sizing: border-box;
}

.carousel-indicators li.active {
    background: $secondary-color;
}
