@import '../../../../utilities/main-styles.scss';
@import '../../../../utilities/variables.scss';
@import '../common_blocks/FrontPage/FrontPage.scss';

.redkey-main{
    @include prime-body();

    background: linear-gradient(180deg, #482BA4 17.49%, #9B2BE0 100%), #5BC815;

    &__info{
        @include prime-info();
        }

    &__text{
        @include prime-text(); 
    }

    &__link{
        @include prime-link();
    }

    &__tablet{
        z-index: 2;
        text-align: center;
        @media screen and (min-width: 1280px) {
            margin-top: -20px;
        }
    }

    &__image{
        @media screen and (max-width: 1279px) {
            width: 100%;
            height: auto;
            
        }
    }
}

.redkey-goal{
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 70px;
    display: flex;
    flex-direction: column;

    &__text-block{
        padding: 0px 7% 0px;
    }

    &__title{
        // @include main-text(500, 32px, 119%);
        // padding-bottom: 3px;
        @include common-title();
    }

    
    &__title::after{
        @include common-title_after();
    }


    &__text{
        @include main-text(normal, 18px, 140%);

        padding: 10px 0;
    }
}

.redkey-design{
    @media screen and (min-width: 1280px) {
        height: 2105px;
    }
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 60px;
   
    display: flex;
    flex-direction: column;

    &__text-block{
        padding: 0px 7% 0px;
    }

    &__title{
        @include common-title();
        padding-bottom: 5px;
    }

    &__title::after{
        @include common-title_after();
    }

    &__text{
        @include main-text(normal, 18px, 140%);
        @media screen and (min-width: 1280px){
            width: 360px;
        }
        padding-top: 20px;
    }

    &__image-block{
        position: relative;
        @media screen and (max-width: 1279px) {
            padding: 0px 7% 0px;
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__image1{
        border: 1px solid #C4C4C4;
        @media screen and (min-width: 1280px) {
            @include image-position(110px, 35px, 1);
        }
        @media screen and (max-width: 500px){
            width: 100%;
            height: auto;
        }
    }

    &__image2{
        @media screen and (min-width: 1280px) {
            @include image-position(-26px, 487px, 2);
        }

        @media screen and (max-width: 1280px) {
            margin-top: 20px;
        }

        @media screen and (max-width: 850px) {
            width: 100%;
            height: auto;
        }
    }

    &__image3{
        @media screen and (min-width: 1280px) {
            @include image-position(-41px, 475px, 1);
        }
        @media screen and (max-width: 1280px) {
            display: none;
        }
    }

    &__image4{
        @media screen and (min-width: 1280px) {
            @include image-position(1015px, 15px, 3);
        }
        @media screen and (max-width: 500px){
            width: 100%;
            height: auto;
        }
        @media screen and (max-width: 1279px) {
            margin-top: 20px;
        }
    }

    &__image5{
        @media screen and (min-width: 1280px) {
            @include image-position(1455px, 487px, 1);
        }

        @media screen and (max-width: 1280px) {
            margin-top: 20px;
        }

        @media screen and (max-width: 715px){
            width: 100%;
            height: auto;
        }
    }
}