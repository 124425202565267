@import '../../utilities/variables.scss';
@import '../../utilities/main-styles.scss';
@import '../../blocks/button/bem-button.scss';

.modal-content{
    background: #FFFFFF;
    box-shadow: 0px 16px 32px rgba(22, 22, 22, 0.16);
    border-radius: 8px;
}

.modal-info {
    @media screen and (min-width: 992px) {
        max-width: 696px;
    }

    &__content{
        margin: 9px;
    }

    &__header{
        margin-top: 5px;
        flex-direction: column;
        border: none;
    }

    &__title{
        @include main-text(500, 24px, 1.7);
    }
        
    &__subtitle{
        @include main-text(normal, 18px, 21px);
    } 
}