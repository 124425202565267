@import '../../../utilities/variables.scss';
@import '../../../utilities/main-styles.scss';

.project-card{
    position: relative;
    padding: 3% 0;

    &__img{
        @media screen and (max-width: 1280px){
            width: 74%;
            height: 32.5vw;
            // width: calc(300px + 4.6vw);
            // height: calc(130px + 2.2vw);
        }
        height: 416px;
        
        margin: 0 13% 0;
        
    }
    
    &__background{
        position: absolute;
        background: rgba(255, 255, 255, 0.8);
        margin: -40px 0 0 104px;

        text-align: center;
    }

    &__title{
        @media screen and (max-width: 1280px){
            @include main-text(500, calc(18px + 1.8vw), 140%);
        }
        @include main-text(500, 48px, 140%);
    }

    &__text{
        @include main-text(normal, 18px, 140%);
        @media screen and (max-width: 1279px){
            width: 90%;
            margin-right: 4%;
            font-size: calc(12px + 0.5vw);
        }
        margin: 24px 0 0 10%;
        width: 72%;
    }
}

.bg-sestrica{
    @media screen and (max-width: 1280px){
        width: calc(100px + 10vw);
        height: calc(30px + 2vw);
        margin-top: -3%;
        margin-left: 8%;
    }
    width: 286px;
    height: 64px;
}

.bg-college{
    @media screen and (max-width: 1280px){
        width: calc(100px + 16vw);
        height: calc(30px + 2vw);
        margin-top: -3%;
        margin-left: 8%;
    }
    width: 345px;
    height: 64px;
}

.bg-h2u{
    @media screen and (max-width: 1280px){
        width: calc(50px + 4vw);
        height: calc(30px + 2vw);   
        margin-top: -3%;
        margin-left: 8%;
    }
    width: 140px;
    height: 64px;
}

.bg-redKey{
    @media screen and (max-width: 1280px){
        width: calc(150px + 15vw);
        height: calc(30px + 2vw);
        margin-top: -3%;
        margin-left: 8%;
    }
    margin: -43px 0 0 114px;
    width: 392px;
    height: 64px;
}