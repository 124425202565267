@import '../../utilities/variables.scss';
@import '../../utilities/main-styles.scss';
@import '../../blocks/button/bem-button.scss';

.products-main{
    
    &-bg{
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
    }

    padding: 5.5% 0 6%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (max-width: 1279px) {
        justify-content: center;
    }

    &__info-block{
        padding: 20px 0 0 5.5%;

        display: flex;
        flex-direction: column;
        @media screen and (max-width: 1279px) {
            text-align: center;
            padding-left: 0px;
        }
    }

    &__title-outer{
        flex-grow: 1;
        width: 100%;
        max-width: 577px;
        @media screen and (max-width: 1279px){
            max-width: 800px;
        }
    }

    &__title{
        @include main-text(300, 52px, 119%);
        @media screen and (max-width: 1279px){
            font-size: calc(30px + 2.2vw);
        }
        
        &-bold{
            font-weight: 500;
        }

        &-normal{
            @media screen and (max-width: 500px){
                font-size: calc(20px + 2.2vw);
                line-height: 100%;
            }
        }
    }

    &__button-block{
        @media screen and (max-width: 1279px) {
            padding-top: 20px;
        }
        flex-grow: 2;
    }

    &__button{
        width: 280px !important;
    }

    &__media-block{
        @media screen and (max-width: 1279px){
            display: none;
        }
    }

    &__tablet{
        @media screen and (max-width: 680px) {
            width: 100%;
            height: auto;
        }
    }

    &__phone{
        @include image-position(72px, -33px, 2);
        @media screen and (max-width: 680px) {
            width: 30%;
            height: auto;
        }
    }
}

.products-media{

    &-bg{
        width: 100%;
        background: rgba(62, 126, 227, 0.05);
    }

    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 36px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    @media screen and (max-width: 1017px) {
        padding-bottom: 16px;
    }

    &__item{
        display: flex;
        @media screen and (max-width: 1017px) {
            padding-bottom: 40px;
        }
        
    }

    &__text{
        @include main-text(500, 24px, 119%);
        @media screen and (max-width: 1279px){
            font-size: calc(18px + 0.4vw);
        }
        align-self: center;
        padding-left: 10px;
        width: 233px;
    }
}

.products-optimization{
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;

    padding: 95px 0 80px;
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 980px) {
        flex-wrap: wrap;
        padding: 40px 0;
    }

    &__text-block{
        padding: 4% 5.5% 0;
        @media screen and (max-width: 1153px) {
            padding-top: 0px;
            padding-right: 0px;
        }
        @media screen and (max-width: 980px){
            padding-right: 5.5%;
        }
    }

    &__title{
        @include main-text(500, 32px, 119%);
        @media screen and (min-width: 980px){
            max-width: 473px;
        }
        @media screen and (max-width: 1279px){
            font-size: calc(24px + 0.6vw);
        }
    }

    &__text{
        @include main-text(300, 18px, 130%);
        @media screen and (min-width: 980px){
            max-width: 488px;
        }
    }

    &__image-block{
        @media screen and (max-width: 980px) {
            padding-top: 20px;
        }
        padding: 0 4% 0;
    }
}

.products-time{
    
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;

    padding: 40px 0;
    display: flex;
    @media screen and (max-width: 980px) {
        flex-wrap: wrap;
    }

    &__text-block{
        padding: 5% 5.5% 0;
        @media screen and (max-width: 1153px) {
            padding-top: 0px;
        }

        @media screen and (min-width: 980px){
            order: 2;
        }
    }

    &__title{
        @include main-text(500, 32px, 119%);
        @media screen and (max-width: 1279px){
            font-size: calc(24px + 0.6vw);
        }
    }
    
    &__text{
        @include main-text(300, 18px, 130%);
        @media screen and (min-width: 980px){
            max-width: 488px;
        }
    }

    &__image-block{
        @media screen and (min-width: 980px){
            order: 1;
            padding-left: 5.5%;
        }
        @media screen and (max-width: 980px){
            padding: 20px 4% 0;
        }
    }
}

.products-mobile{
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;

    padding: 60px 0 90px;
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 980px) {
        flex-wrap: wrap;
        padding: 40px 0;
    }  
    
    &__text-block{
        padding: 0 5.5% 0;
        @media screen and (max-width: 980px) {
            padding-top: 20px;
        }
    }

    &__title{
        @include main-text(500, 32px, 119%);
        @media screen and (max-width: 1279px){
            font-size: calc(24px + 0.6vw);
        }
        @media screen and (min-width: 980px){
            max-width: 424px;
        }
    }

    &__text{
        @include main-text(300, 18px, 130%);
        @media screen and (min-width: 980px){
            max-width: 648px;
        }
        @media screen and (max-width: 980px){
            padding-top: 10px;
        }
    }

    &__image-block{
        padding-right: 5.5%;
        @media screen and (max-width: 980px) {
            padding: 0 5.5% 0;
        }
    }
}

.products-monitoring{
    
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;

    padding: 40px 0;
    display: flex;
    @media screen and (max-width: 980px) {
        flex-wrap: wrap;
    }

    &__text-block{
        padding: 9% 5.5% 0;
        @media screen and (max-width: 1153px) {
            padding-top: 0px;
        }

        @media screen and (min-width: 980px){
            order: 2;
        }
    }

    &__title{
        @include main-text(500, 32px, 119%);
        @media screen and (max-width: 1279px){
            font-size: calc(24px + 0.6vw);
        }
    }
    
    &__text{
        @include main-text(300, 18px, 130%);
        @media screen and (min-width: 980px){
            max-width: 488px;
        }
    }

    &__image-block{
        @media screen and (min-width: 980px){
            order: 1;
            padding-left: 5.5%;
        }
        @media screen and (max-width: 980px){
            padding: 20px 4% 0;
        }
    }
}

.products-integration{
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;

    padding: 150px 0 100px;
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 980px) {
        flex-wrap: wrap;
        padding: 40px 0;
    }
    
    &__text-block{
        padding: 0 5.5% 0;
    }

    &__title{
        @include main-text(500, 32px, 119%);
        @media screen and (max-width: 1279px){
            font-size: calc(24px + 0.6vw);
        }
        @media screen and (min-width: 980px){
            max-width: 424px;
        }
    }

    &__text{
        @include main-text(300, 18px, 130%);
        @media screen and (min-width: 980px){
            max-width: 488px;
        }
        @media screen and (max-width: 980px){
            padding-top: 10px;
        }
    }

    &__image-block{
        display: flex;
        align-items: center;
        padding: 0 2% 0 5.5%;
        @media screen and (max-width: 980px){
            margin: 20px 5.5% 0;
        }
    }
    
    &__img{
        @media screen and (max-width: 1280px){
            width: 100%;
        }
    }
}

.products-functions{
    &__img{
        width: 100%;
    }
    &__bg{
        width: 100%;
        background: rgba(196, 196, 196, 0.1); 
    }
}