@import '../../../../../utilities/main-styles.scss';
@import '../../../../../utilities/variables.scss';
@import '../../../../../blocks/button/bem-button.scss';

@mixin prime-body {
    @media screen and (min-width: 1280px) {
        height: 900px;
    }
    @media screen and (max-width: 768px) {
        margin-top: -250px;
        padding-top: 300px;
    }
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@mixin prime-info {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@mixin prime-link {
    @include main-text(normal, 24px, 140%);

    color: #FFFFFF !important;
    text-decoration-line: underline;
    z-index: 3;
}

@mixin prime-text {
    margin-top: 15px;
    @include main-text(500, 32px, 119%);
    
    color: #FFFFFF !important;  
}

@mixin common-title {
    @include main-text(500, 32px, 119%);

    // @media screen and (min-width: 1280px) {
    //     margin-left: 88px;
    // }
    padding-bottom: 3px;
    position: relative;
}

@mixin common-title_after {
    content: "";
    position: absolute;
    border-bottom: 2px solid $secondary-color;
    width: 28px;
    left: 0;
    bottom: 0;
}


.contact-comp{
    position: relative;
    height: 400px; 
    text-align: center;

    &__upper-union{
        position: absolute;
        right: 0;
        top: 0;
        width: 31%;
    }

    &__lower-union{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 24%;
    }

    &__info-block{
        @media screen and (max-width: 1280px) {
            padding: 0 5.5% 0;
        }
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    &__text{
        @include main-text(500, 32px, 119%);

        padding-bottom: 30px;
    }
}