@mixin main-text($txt-weight, $txt-size, $txt-height) {
    font-family: $primary-family;
    font-style: $primary-style;
    color: $primary-color;  
    font-weight: $txt-weight;
    font-size: $txt-size;
    line-height: $txt-height;  
}

@mixin image-position($top-margin, $left-margin, $index, $transformation: none){
    position: absolute;
    margin-top: $top-margin;
    margin-left: $left-margin;
    transform: $transformation;
    z-index: $index;
}