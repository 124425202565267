@import '../../../utilities/variables.scss';
@import '../../../utilities/main-styles.scss';

$bg-image: url('../../../images/tablet_background.png');
$sestrica_grey: url('../../../images/sestrica_logo_grey.png');
$sestrica_color: url('../../../images/sestrica_logo.png');
$college_grey: url('../../../images/college_logo_grey.png');
$college_color: url('../../../images/college_logo.png');
$h2u_grey: url('../../../images/h2u_logo_grey.png');
$h2u_color: url('../../../images/h2u_logo.png');
$redKey_grey: url('../../../images/red-key_logo_grey.png');
$redKey_color: url('../../../images/red-key_logo.png');

.logistic-block{
    width: 100%;
}

.dev{
    &-bg{
        background-image: linear-gradient(89.02deg, #00A6F8 3.66%, #3E7EE3 110.42%),  $bg-image;
        background-blend-mode: multiply;
        background-size: cover;
    }

    &-main{
        max-width: 1280px;
        margin: 0 auto;
        padding: 56px 0 78px;
    }

    &__text-block{
        padding-left: 5.5%;
    }

    &__title{
        @include main-text(500, 48px, 119%);
        @media screen and (max-width: 1279px ){
            font-size: calc(30px + 1.4vw);
        }
        color: #FFFFFF;
        padding-bottom: 32px;
    }

    &__button{
        background: #FFFFFF !important;
        box-shadow: none;

        &-text{
            @include main-text(500, 16px, 19px);
            
            text-align: center;
            text-transform: uppercase;
            color: $secondary-color;
            border-radius: 28px;
        }
    }

    &__button:hover{
        background: #FFFFFF;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16) !important;
        border-radius: 8px;
    }

    &__button:focus{
        background: linear-gradient(180deg, #E9EEF5 0%, #FFFFFF 89.29%) !important;
        border-radius: 8px;
    }
}

.collab{
    &-bg{
        background: #F2F2F2;
    }
    
    &-main{
        max-width: 1280px;
        margin: 0 auto;
        padding: 40px 0 75px;
    }

    &__title{
        @include main-text(500, 48px, 119%);
        @media screen and (max-width: 1279px){
            font-size: calc(30px + 1.4vw);
        }

        padding: 0 5.5% 70px;
    }

    &__items-menu{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 0 5.5% 0;
    }

    &__sestrica-img{
        background-image: $sestrica_grey;
        width: 137.2px;
        height: 140px;
        @media screen and (max-width: 768px){
            margin: 10px;
        }
        transition: 0.5s ease-in-out;
    }

    &__sestrica-img:hover{
        background-image: $sestrica_color;
        transition: 0.5s ease-in-out;
    }

    &__college-img{
        background-image: $college_grey;
        width: 154.45px;
        height: 96px;
        position: relative;
        transform: translateY(20%);
        @media screen and (max-width: 768px){
            margin: 10px;
        }
        transition: 0.5s ease-in-out;
    }

    &__college-img:hover{
        background-image: $college_color;
        transition: 0.5s ease-in-out;
    }

    &__h2u-img{
        background-image: $h2u_grey;
        width: 232.93px;
        height: 96px;
        position: relative;
        transform: translateY(20%);
        @media screen and (max-width: 768px){
            margin: 10px;
        }
        transition: 0.5s ease-in-out;
    }

    &__h2u-img:hover{
        background-image: $h2u_color;
        transition: 0.5s ease-in-out;
    }

    &__redKey-img{
        background-image: $redKey_grey;
        width: 140px;
        height: 140px;
        @media screen and (max-width: 768px){
            margin: 10px;
        }
        transition: 0.5s ease-in-out;
    }

    &__redKey-img:hover{
        background-image: $redKey_color;
        transition: 0.5s ease-in-out;
    }
}