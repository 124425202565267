@import '../../../../utilities/main-styles.scss';
@import '../../../../utilities/variables.scss';
@import '../common_blocks/FrontPage/FrontPage.scss';

.hydrogen-main{
    @include prime-body();
    background: linear-gradient(0deg, #3D143C, #3D143C), #F61616;

    &__logo{
        @media screen and (max-width: 768px) {
            width: 80%;
            height: auto;  
        }
    }

    &__info{
       @include prime-info();
    }

    &__link{
        @include prime-link();
        margin-top: 20px;
    }

    &__tablet{
        z-index: 2;
        text-align: center;
        @media screen and (min-width: 1280px) {
            margin-top: -20px;
        }
    }

    &__image{
        @media screen and (max-width: 1279px) {
            width: 100%;
            height: auto;  
        }
    }
}

.hydrogen-content{
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 70px;
}

.hydrogen-goal{

    &__text-block{
        padding: 0 7% 0;
        display: flex;
        flex-wrap: wrap;
    }

    &__title{
        @include common-title();
        float: left;
        align-self: flex-start;
    }

    &__title::after{
        @include common-title_after();
    }

    &__text{
        @include main-text(normal, 18px, 140%);

        @media screen and (min-width: 1280px){
            width: 752px;
            padding-left: 64px;
        }
        text-align: justify;
        padding-top: 10px;
        
    }
}

.hydrogen-logo{
    padding-top: 80px;

    &__text-block{
        padding: 0 7% 0;
    }

    &__title{
        @include common-title();
    }

    &__title::after{
        @include common-title_after();
    }

    &__image-block{
        @media screen and (min-width: 517px) {
            justify-content: space-evenly;
        }
        padding: 50px 7% 0;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    }

    &__text{
        @include main-text(normal, 18px, 140%);

        padding-top: 22px;
        text-align: justify;
        color: #969696;
    }
}

.hydrogen-past{
    padding-top: 40px;

    &__text-block{
        padding: 0 7% 0;
    }
    
    &__title{
        @include common-title();
    }

    &__title::after{
        @include common-title_after();
    }

    &__text{
        @include main-text(normal, 18px, 140%);
        
        padding-top: 20px;
        
    }

    &__image-block{
        margin-top: 18px;
        position: relative;
        @media screen and (min-width: 1280px){
            height: 872px;
        }

        @media screen and (max-width: 1280px){
            padding: 0 7% 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    &__img1{
        @media screen and (min-width: 1280px) {
            @include image-position(11px, 0px, 1);
        }

        @media screen and (max-width: 530px){
            width: 100%;
            height: auto;
        }
    }

    &__img2{
        @media screen and (min-width: 1280px) {
            @include image-position(-5px, 590px, 1);
        }

        @media screen and (max-width: 750px){
            width: 100%;
            height: auto;
        }
    }

    &__img3{
        @media screen and (min-width: 1280px) {
            @include image-position(540px, 30px, 1);
        }
        @media screen and (max-width: 575px){
            width: 100%;
            height: auto;
        }
    }

    &__img4{
        @media screen and (min-width: 1280px) {
            @include image-position(325px, 275px, 1);
        }

        @media screen and (max-width: 400px){
            width: 100%;
            height: auto;
        }
    }
}

.hydrogen-newstyle{
    padding-top: 70px;
    &__text-block{
        padding: 0 7% 0;
    }

    &__title{
        @include common-title();
    }

    &__title::after{
        @include common-title_after();
    }

    &__text{
        @include main-text(normal, 18px, 140%);

        @media screen and (min-width: 1280px) {
            width: 1120px;
        }
        padding-top: 20px;
    }

    &__image-block{
        @media screen and (min-width: 1280px) {
            height: 1600px;    
        }
        @media screen and (max-width: 1280px) {
            padding: 0 7% 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        margin-top: 60px;
        position: relative;
        
    }

    &__img1{
        @media screen and (min-width: 1280px) {
            @include image-position(151px, 72px, 1);
        }

        @media screen and (max-width: 440px){
            width: 100%;
            height: auto;
        }
    }

    &__img2{
        @media screen and (min-width: 1280px) {
            @include image-position(0px, 460px, 1);
        }

        @media screen and (max-width: 440px){
            width: 100%;
            height: auto;
        }
    }

    &__img3{
        @media screen and (min-width: 1280px) {
            @include image-position(0px, 850px, 1);
        }

        @media screen and (max-width: 440px){
            width: 100%;
            height: auto;
        }
    }

    &__img4{
        @media screen and (min-width: 1280px) {
            @include image-position(770px, 72px, 1);
        }

        @media screen and (max-width: 440px){
            width: 100%;
            height: auto;
        }
    }

    &__img5{
        @media screen and (min-width: 1280px) {
            @include image-position(730px, 460px, 1);
        }

        @media screen and (max-width: 440px){
            width: 100%;
            height: auto;
        }
    }

    &__img6{
        @media screen and (min-width: 1280px) {
            @include image-position(1090px, 72px, 1);
        }

        @media screen and (max-width: 440px){
            width: 100%;
            height: auto;
        }
    }

    &__img7{
        @media screen and (min-width: 1280px) {
            @include image-position(1050px, 460px, 1);
        }

        @media screen and (max-width: 440px){
            width: 100%;
            height: auto;
        }
    }
}