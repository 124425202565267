@import '../../utilities/variables.scss';
@import '../../utilities/main-styles.scss';
@import '../../blocks/button/bem-button.scss';

.projects{
    &__block{
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        margin-bottom: 70px;
    }

    &__text-block{
        padding: 5% 5.5% 0;
    }

    &__title{
        @include main-text(500, 32px, 38px);
    }

    &__text{
        @include main-text(normal, 24px, 28px);
    }

    
}
