@import '../../../../../utilities/main-styles.scss';
@import '../../../../../utilities/variables.scss';

.alert{
    &__info-block{
        @media screen and (min-width: 1280px) {
            height: 80px;
        }  
        width: 90%;
        max-width: 1136px;
        margin: 0 auto; 
        @media screen and (max-width: 1279px){
            margin-top: 3%;
            padding: 1.5%;
        }
        background-color: rgba(62, 126, 227, 0.1);
        border-radius: 12px;
        margin-top: auto;
        align-self: center;
    }

    &__info-text{
        @include main-text(normal, 24px, 140%);

        text-align: center;
        color: $secondary-color;
        @media screen and (min-width: 1280px){
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }

        @media screen and (max-width: 1279px){
            font-size: calc(12px + 1vw);
        }
    }
}