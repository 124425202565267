@import '../../../../utilities/main-styles.scss';
@import '../../../../utilities/variables.scss';
@import '../common_blocks/FrontPage/FrontPage.scss';

.college-main{
    @include prime-body();
    background: radial-gradient(138.35% 138.35% at 50% 125.62%, #E31E24 0%, #2609A8 100%), #F7F7F7;

    &__info{
       @include prime-info();
    }

    &__text{
        @include prime-text(); 
    }

    &__link{
        @include prime-link();
    }

    &__tablet{
        z-index: 2;
        text-align: center;
        @media screen and (min-width: 1280px) {
            margin-top: -20px;
        }
    }

    &__image{
        @media screen and (max-width: 1279px) {
            width: 100%;
            height: auto;  
        }
    }
    
}

.college-goal{
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;

    padding-top: 70px;
    display: flex;
    flex-direction: column;

    &__text-block{
        padding: 0 7% 0; 
    }

    &__title{
        @include common-title();
        float: left;
    }

    &__title::after{
        @include common-title_after();
    }

    &__text{
        @include main-text(normal, 18px, 140%);
        
        @media screen and (min-width: 1280px){
            width: 752px;
            padding-left: 64px;
        }

        float: left;
        text-align: justify;
        padding-top: 10px;
        
    }

    &__stats-block{
        @media screen and (min-width: 1280px){
            padding-top: 85px;
        }

        @media screen and (max-width: 1279px){
            padding-top: 7%;
        }

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-content: center;
        
    }

    &__stat-text{
        @include main-text(normal, 64px, 119%);
        text-align: center;
        color: #000000;
    }

    &__info-text{
        @include main-text(normal, 24px, 119%);
        text-align: center;
        padding-top: 32px;
        color: #000000;
    }
}

.sport_text{
    @include image-position(-142px, 65px, 1);
}

.uni_text{
    @include image-position(-142px, 31px, 1);
}

.result_text{
    @include image-position(-142px, 51px, 1);
}

.college-design{
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;

    padding-top: 85px;
    position: relative;

    &__text-block{
        @media screen and (min-width: 1280px) {
            @include image-position(130px, 0px, 4);
            right: 100px;
        }
        @media screen and (max-width: 1280px) {
            padding: 0 7% 0;
        }
    }

    &__title{
        @include common-title();
        padding-left: 0px;
        padding-bottom: 4px;
        margin-left: 0px !important;
    }

    &__title::after{
        @include common-title_after();
        left: 0;
    }

    &__text{
        @include main-text(normal, 18px, 140%);
        @media screen and (min-width: 1280px) {
            width: 320px;
        }
        padding-top: 20px;
        
    }

    &__monitor-block{
        @media screen and (min-width: 1280px){
            height: 745px;
        }
        @media screen and (max-width: 1279px) {
            padding: 0 7% 0;
        }
    }

    &__monitor{
        @media screen and (min-width: 1280px){
            @include image-position(-20px, 0px, 3);
        }
        @media screen and (max-width: 825px){
            width: 100%;
            height: auto;
        }
    }
    
    &__red-back{
        @media screen and (min-width: 1280px){
            @include image-position(-33px, 671px, 1);
        }
        @media screen and (max-width: 1279px) {
            display: none;
        }
    }

    &__white-back{
        @media screen and (min-width: 1280px) {
            @include image-position(8px, 675px, 2);
        }

        @media screen and (max-width: 1279px) {
            display: none;
        }
    }

    &__achieve-block{
        @media screen and (min-width: 1280px) {
            position: relative;
            height: 1073px;
        }
        @media screen and (max-width: 1279px) {
            padding: 0 7% 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    &__sports{
        @media screen and (min-width: 1280px) {
            @include image-position(0px, 0px, 1);
        }

        @media screen and (max-width: 776px){
            width: 100%;
            height: auto;
        }
    }

    &__enroll{
        @media screen and (min-width: 1280px) {
            @include image-position(0px, 789px, 1);
        }

        @media screen and (max-width: 510px){
            width: 100%;
            height: auto;
        }
    }

    &__universities{
        @media screen and (min-width: 1280px) {
            @include image-position(557px, 0px, 1);
        }
        
        @media screen and (max-width: 715px){
            width: 100%;
            height: auto;
        }
    }

    &__form{
        @media screen and (min-width: 1280px) {
            @include image-position(716px, 516px, 2);
        }
        @media screen and (max-width: 530px){
            width: 100%;
            height: auto;
        }
    }
}

@mixin images-border {
    margin: 10px 20px;
    border: 1px solid #C4C4C4;
}

.college-adaptive{
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;

    padding-top: 40px;
    
    &__text-block{
        @media screen and (min-width: 1280px) {
            @include image-position(110px, 56px, 3);
        }
        @media screen and (max-width: 1280px){
            padding: 0 7% 0;
        }
    }

    &__title{
        @include common-title();
        @media screen and (min-width: 1280px) {
            margin-left: 88px;
        }
    }

    &__title::after{
        @include common-title_after();
    }

    &__text{
        @include main-text(normal, 18px, 140%);
        @media screen and (min-width: 1280px) {
            padding-left: 88px;
            width: 480px;
        }
        padding-top: 20px;
    }

    &__phone-block{
        @media screen and (min-width: 1280px){
            height: 900px;
        }
    }
    
    &__grey{
        @media screen and (min-width: 1280px) {
            @include image-position(-238px, -16px, 1);
        }

        @media screen and (max-width: 1279px) {
            display: none;
        }
    }

    &__white{
        @media screen and (min-width: 1280px) {
            @include image-position(32px, 50px, 2);
        }
        @media screen and (max-width: 1279px) {
            display: none;
        }
    }

    &__phone{
        @media screen and (min-width: 1280px) {
            @include image-position(0px, 636px, 2);
        }

        @media screen and (max-width: 600px){
            width: 100%;
            height: auto;
        }
    }

    &__articles-block{
        @media screen and (min-width: 1280px) {
            height: 1440px;
        }
        @media screen and (max-width: 1279px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 0 7% 0;
        }
    }

    &__img1{
        border-radius: 16px;

        @media screen and (min-width: 1280px) {
            @include image-position(25px, 105px, 2);
        }

        @media screen and (max-width: 1279px){
            @include images-border();
        }
    }

    &__img2{
        border-radius: 16px;

        @media screen and (min-width: 1280px) {
            @include image-position(115px, 380px, 2);
        }

        @media screen and (max-width: 1279px){
            @include images-border();
        }
    }

    &__img3{
        border-radius: 16px;

        @media screen and (min-width: 1280px) {
            @include image-position(25px, 655px, 2);
        }

        @media screen and (max-width: 1279px){
            @include images-border();
        }
    }

    &__img4{
        border-radius: 16px;

        @media screen and (min-width: 1280px) {
            @include image-position(115px, 930px, 2);
        }

        @media screen and (max-width: 1279px){
            @include images-border();
        }
    }

    &__img5{
        border-radius: 16px;

        @media screen and (min-width: 1280px) {
            @include image-position(772px, 105px, 2);
        }

        @media screen and (max-width: 1279px){
            @include images-border();
        }

    }

    &__img6{
        border-radius: 16px;

        @media screen and (min-width: 1280px) {
            @include image-position(862px, 380px, 2);
        }

        @media screen and (max-width: 1279px){
            @include images-border();
        }
    }

    &__img7{
        border-radius: 16px;

        @media screen and (min-width: 1280px) {
            @include image-position(772px, 655px, 2);
        }

        @media screen and (max-width: 1279px){
            @include images-border();
        }
    }

    &__img8{
        border-radius: 16px;

        @media screen and (min-width: 1280px) {
            @include image-position(862px, 930px, 2);
        }

        @media screen and (max-width: 1279px){
            @include images-border();
        }
    }
}
