@import '../../../utilities/variables.scss';
@import '../../../utilities/main-styles.scss';

.main-business-back{
    width: 100%;
    background: #F2F2F2;
}

.main-business{
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 40px 0 80px;
}

.main-title{
    @include main-text(500, 48px, 119%);
    padding-left: 5.5%;
    @media screen and (max-width: 1279px) {
        font-size: calc(30px + 1.4vw);
    }
}

.deck-container{   
    @media screen and (min-width: 1280px) {
        flex-wrap: nowrap;
    }
    @media screen and (max-width: 440px) {
        padding-left: 1%;
        padding-right: 1%;
    }
    padding: 62px 5.5% 0px;
    justify-content: center;
    width: 100%;
}

.card-container{
    padding-top: 62px;
}

.custom-card{
    position: relative;
    max-width: 368px;
    min-width: 368px;
    min-height: 455px;
    margin: 8px !important;
    background: #FFFFFF;
    border-radius: 6px;
    align-self: center;
    text-decoration: none;

    
    &__image-container{
        padding: 16% 11% 9%;
        position: relative;
        height: 276px;
    }

    &__image{
        position: absolute;
        z-index: 2;
    }

    &__title{
        font-weight: 500;
        font-size: 32px;
        line-height: 119%;
    }

    &__text{
        position: relative;
        font-weight: 300;
        font-size: 24px;
        line-height: 119%;
        z-index: 2;
    }
}

.custom-card:hover{
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.16));
}

.custom-card__redirect{
    text-decoration: none !important;
    color: inherit;
}

.custom-card__redirect:hover{
    text-decoration: none !important;
    color: inherit;
}

.custom-badge{
    width: 80px;
    height: 24px;
    background: rgba(255, 63, 0, 0.2);
    border-radius: 4px;
    margin-left: 13px;
    vertical-align: -10%;
    padding-top: 0px;

    &__text{
        @include main-text(normal, 18px, 119%);
        text-align: center;
        vertical-align: text-top;
        color: #F0440B;
    }
}

.image-shadow{
    box-shadow: 0px 0px 4px rgba(52, 63, 75, 0.2);
}

.logistic-bottom{
    @media screen and (max-width: 1279px) {
        display: none;
    }

    @include image-position(215.5px, -40.5px, 1);
}

.logistic-top{
    @media screen and (max-width: 1279px) {
        display: none;
    }

    @include image-position(-66px, 240px, 1);
}

.crm-bottom{
    @include image-position(229px, 190px, 1);

    @media screen and (max-width: 1279px){
        display: none;
    }
}

.website-top{
    @media screen and (max-width: 1279px) {
        display: none;
    }

    @include image-position(-59px, -40.5px, 0);
}