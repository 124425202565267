@import '../../utilities/variables.scss';
@import '../../utilities/main-styles.scss';
@import '../../blocks/button/bem-button.scss';

.footer-panel{
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 40px;
}

.footer{

    &__title{
        @include main-text(500, 24px, 119%);
        
        padding-left: 4.5%;
    }

    &__text{
        @include main-text(bold, 32px, 119%);
        @media screen and (max-width: 767px){
            font-size: calc(24px + 1vw);
        }
    }

    &__image{
        padding-right: 10px;
        padding-bottom: 10px;
    }

    &__contact-block{
        padding: 50px 4.5% 4.5%;
        display: flex;
        flex-direction: column;
        @media screen and (min-width: 1000px) { 
            flex-direction: row;
        }
        justify-content: space-between;
    }

    &__contact-item{
        padding-top: 12px;
        padding-bottom: 30px;
    }

    &__button-item{
        padding-bottom: 30px;
    }
}