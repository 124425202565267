@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik/Rubik-Bold.eot');
    src: url('../fonts/Rubik/Rubik-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik/Rubik-Bold.woff2') format('woff2'),
        url('../fonts/Rubik/Rubik-Bold.woff') format('woff'),
        url('../fonts/Rubik/Rubik-Bold.ttf') format('truetype'),
        url('../fonts/Rubik/Rubik-Bold.svg#Rubik-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik/Rubik-Medium.eot');
    src: url('../fonts/Rubik/Rubik-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik/Rubik-Medium.woff2') format('woff2'),
        url('../fonts/Rubik/Rubik-Medium.woff') format('woff'),
        url('../fonts/Rubik/Rubik-Medium.ttf') format('truetype'),
        url('../fonts/Rubik/Rubik-Medium.svg#Rubik-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik/Rubik-Light.eot');
    src: url('../fonts/Rubik/Rubik-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik/Rubik-Light.woff2') format('woff2'),
        url('../fonts/Rubik/Rubik-Light.woff') format('woff'),
        url('../fonts/Rubik/Rubik-Light.ttf') format('truetype'),
        url('../fonts/Rubik/Rubik-Light.svg#Rubik-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik/Rubik-Regular.eot');
    src: url('../fonts/Rubik/Rubik-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik/Rubik-Regular.woff2') format('woff2'),
        url('../fonts/Rubik/Rubik-Regular.woff') format('woff'),
        url('../fonts/Rubik/Rubik-Regular.ttf') format('truetype'),
        url('../fonts/Rubik/Rubik-Regular.svg#Rubik-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


$primary-family: 'Rubik', sans-serif;
$primary-color:  #293A56;
$primary-style: normal;
$secondary-color: #3E7EE3;