@import '../../utilities/variables.scss';
@import '../../utilities/main-styles.scss';
@import '../../blocks/button/bem-button.scss';

.contacts{
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 50px;

    &__text-block{
        padding: 0 5.5% 0;
        display: flex;
        flex-direction: column;
    }

    &__title{
        @include main-text(500, 32px, 38px);
    }

    &__text{
        @include main-text(normal, 24px, 28px);
    }
}