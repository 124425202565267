@import '../../../../../utilities/main-styles.scss';
@import '../../../../../utilities/variables.scss';

.switcher{
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;

    &__button-block{
        padding: 8% 5.5% 5%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        @media screen and (max-width: 568px) {
            justify-content: center;

        }
    }

    &__image{
        display: inline-block;
        margin-top: -2px;
    }

    &__text{
        @include main-text(normal, 18px, 119%);

        display: inline-block;
        padding: 0px 16px;
    }

    &__bottom{
        @media screen and(max-width: 568px){
            padding-top: 20px;
        }
    }
}
